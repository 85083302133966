<template>
    <div class="message-text">
        <div class="body">
            <div class="noti-title">
                <span>약속 취소 안내</span>
            </div>
            <div v-if="content.canceled_by_me === false" class="noti-body">
                약속 변경 도중 상대방이 약속을 취소하실 경우에도 취소 수수료의 책임은
                <span class="c-red f-bold">약속 변경을 신청하신 본인</span>에게 있습니다. 약속 변경 중 상대방이 약속
                취소를 신청함에 따라, {{ scheduleDate }} 약속에 대한 취소 수수료를 납부해주셔야 합니다. 약속 변경 신청
                사유가 부득이한 사유의 경우, 수수료 면제 요청이 가능합니다.
            </div>
            <div v-else class="noti-body">
                <div v-if="scheduleDateConfirmed === null">
                    {{ scheduleTargetName }}님과의 약속에 대한 취소 수수료를 납부해주세요. 부득이한 사유의 경우 수수료
                    면제 요청이 가능합니다.
                </div>
                <div v-else>
                    취소하신 {{ scheduleDate }} 약속에 대한 취소 수수료를 납부해주세요. 부득이한 사유의 경우 수수료 면제
                    요청이 가능합니다.
                </div>
            </div>

            <div class="noti-button flex">
                <BottomButton
                    class="bottom-button-sub m-t-6 m-b-5"
                    @click="onClickSubmit"
                    label="수수료 납부하기"
                    :black-btn="true"
                    :non-fixed="true"
                />
                <BottomButton
                    class="bottom-button-req"
                    @click="onClickRequest"
                    label="수수료 면제 요청하기"
                    :black-btn="true"
                    :non-fixed="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { getPlanNameById } from '@/store/data/product'
import scheduleService from '@/services/schedule'

export default {
    name: 'ActionRequestCancel',
    props: ['message', 'item'],
    data: () => ({
        stats: [],
    }),
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        me() {
            return this.$store.getters.me
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        planDetail() {
            if (this.item) {
                return getPlanNameById(this.item.product_id)
            } else {
                return null
            }
        },
        targetInfo() {
            const name = this.content.target_name
            const replacedName = name.charAt(0) + 'o' + 'o'

            const age = this.content.target_age
            const job = this.content.target_job

            return `${replacedName} (${age}, ${job})`
        },
        usedTicket() {
            return this.content.used_ticket
        },
        title() {
            return this.content.name
        },
        scheduleDate() {
            const date = new Date(this.content.date_confirmed)
            let month = date.getMonth() + 1
            let day = date.getDate()

            month = month < 10 ? '0' + month : month
            day = day < 10 ? '0' + day : day

            const formattedDate = `${month}월 ${day}일`

            return formattedDate
        },
        scheduleDateConfirmed() {
            const dateConfirmed = this.content.date_confirmed
            return dateConfirmed
        },
        scheduleTargetName() {
            const targetName = this.content.target_name
            const modifiedName = targetName.charAt(0) + 'OO'
            return modifiedName
        },
    },
    methods: {
        async onClickSubmit() {
            const payload = {
                user_id: this.content.user_id,
                schedule_id: this.content.schedule_id,
            }

            const res = await scheduleService.getScheduleRequest(payload)
            if (res.msg === 'waived') {
                return this.$alert('취소 페널티가 면제되었습니다.')
            } else if (res.msg === 'done') {
                return this.$alert('이미 수수료를 납부했습니다.')
            } else {
                this.$router.push({
                    name: 'PaymentCancelFeePage',
                    params: {
                        product: this.content.penalty_product,
                        scheduleDate: this.content.date_confirmed,
                        targetName: this.content.target_name,
                        scheduleId: this.content.schedule_id,
                    },
                })
            }
        },
        async onClickRequest() {
            const payload = {
                user_id: this.content.user_id,
                schedule_id: this.content.schedule_id,
            }

            const res = await scheduleService.getScheduleRequest(payload)
            if (res.msg === 'waived') {
                return this.$alert('취소 페널티가 면제되었습니다.')
            } else if (res.msg === 'done') {
                return this.$alert('이미 수수료를 납부했습니다.')
            } else {
                this.$router.push({
                    name: 'WaiveRequestPage',
                    params: { product: this.content.penalty_product, scheduleId: this.content.schedule_id },
                })
            }
        },
    },
}
</script>
<style scoped lang="scss">
.body {
    padding: 9px 4px;
    .noti-tester {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        align-items: center;
    }
    .noti-title {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }
    .noti-body {
        font-size: 14px;
        margin-top: 8px;
        color: #111111;
        line-height: 26px;
    }
    .noti-blue-box {
        margin-top: 8px;
        background: #f0f8ff;
        border-radius: 8px;
        color: #111111;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 24px;
        .noti-time {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
        }
    }

    ::v-deep .bottom-button > button {
        height: 40px !important;
    }
}
</style>
